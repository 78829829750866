<template>
	<div class="app-container">
		<el-form ref="postForm" :model="postForm" label-width="80px" label-position="top" class="mt-3" @submit.native.prevent>
			<div class="col-12">
				<div class="row">
					<div class="col-12 col-sm-12 col-md-6 mt-3 mb-3">
						<label>{{$t('msg.msg_restore_system')}}</label> <br/>
						
						<el-button type="primary" @click="onSubmit" :loading="buttonloading">{{$t('button.submit')}}</el-button>
						<el-button @click="onClose" type="danger" :loading="buttonloading">{{$t('button.close')}}</el-button>
					</div>
					
					<div class="col-12 col-sm-12 col-md-6">
						<label>Command</label>
						<ul>
							<li style="font-size:13px" v-for="(item,index) in cleanSql" :key="index">{{item}}</li>
						</ul>
					</div>

				</div>
				
				<div class="row">
					<div class="col-12 col-sm-12 col-md-6">
						<label>Table</label>
						<ul>
							<li style="font-size:13px" v-for="(item,index) in cleanTable" :key="index">{{item}}</li>
						</ul>
					</div>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
import { getLocalStorage } from "@/system/store/localstorage";
import { postMethod } from "@/system/model/post/post";
import { getDAES, getEAES } from "@/system/library/security";

export default{
	data(){
		return {
			loading: true,
			currentJob: '',
			buttonloading: false,
			permissionList: [],
			errors: [],
			cleanSql: [],
			cleanTable: [],
			cleanMongo: [],
			submitForm: {
				id: ''
			},postData: {
				data: '',
				language: ''
			},postForm: {
				security: ''
			},permission:{
                index:'GGESRB',
                DBsubmit:'GFFFAX',
            }
		}
	}, methods: {
		initial(){
			if(this.permissionList.includes(this.permission.index)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var self = this;
				var result = postMethod('developer/restore/index',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));
					if(value.valid){
						self.cleanSql = data.clean_sql;
						self.cleanTable = data.clean_table;
					}
					
					self.loading = false;
					self.buttonloading = false;
				});
			}
				
		},popupErrorMessage(text){
			this.$alert(text, this.$t('msg.msg_error_title'), {
				type: 'error',
				dangerouslyUseHTMLString: true
			});
			
			this.errors = [];
		},resetPostData(){
			this.postData.data = '';
		},onSubmit(){
			if(this.permissionList.includes(this.permission.DBsubmit)){
				this.buttonloading = true;
				this.$prompt(this.$t('msg.msg_security_password'), this.$t('msg.msg_restore_system'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputType: 'password'
				}).then(({ value }) => {
					
					this.postForm.security = value;
					this.postData.data = getEAES(JSON.stringify(this.postForm));
					var self = this;
					var text = '';
					var result = postMethod("developer/restore/DBsubmit", this.postData);
					result.then(function(value) {
						var data = JSON.parse(getDAES(value.data));

						if(value.valid){
							self.$message({
								type: 'success',
								message: data.returnMsg
							});
							
							self.postForm.security = '';
						}else{
							self.errors = data.returnMsg;
							
							self.errors.forEach(function (value){
								text+= value+"<br/>";
							});
							
							self.popupErrorMessage(text);
						}
						
						self.buttonloading = false;
					});
				}).catch(() => {
					this.buttonloading = false;
				});       
			}
		},onClose(){
			this.$emit('closeCurrentTags');
		}
	}, created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
		this.postData.language = currentLang;
		this.initial();
	}
}
</script>